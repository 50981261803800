exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-templates-affordability-availability-js": () => import("./../../../src/templates/affordability-availability.js" /* webpackChunkName: "component---src-templates-affordability-availability-js" */),
  "component---src-templates-affordable-housing-need-js": () => import("./../../../src/templates/affordable-housing-need.js" /* webpackChunkName: "component---src-templates-affordable-housing-need-js" */),
  "component---src-templates-client-home-disabled-js": () => import("./../../../src/templates/client-home-disabled.js" /* webpackChunkName: "component---src-templates-client-home-disabled-js" */),
  "component---src-templates-client-home-js": () => import("./../../../src/templates/client-home.js" /* webpackChunkName: "component---src-templates-client-home-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-housing-consumption-js": () => import("./../../../src/templates/housing-consumption.js" /* webpackChunkName: "component---src-templates-housing-consumption-js" */),
  "component---src-templates-housing-market-js": () => import("./../../../src/templates/housing-market.js" /* webpackChunkName: "component---src-templates-housing-market-js" */),
  "component---src-templates-housing-stress-js": () => import("./../../../src/templates/housing-stress.js" /* webpackChunkName: "component---src-templates-housing-stress-js" */),
  "component---src-templates-housing-supply-js": () => import("./../../../src/templates/housing-supply.js" /* webpackChunkName: "component---src-templates-housing-supply-js" */),
  "component---src-templates-population-households-js": () => import("./../../../src/templates/population-households.js" /* webpackChunkName: "component---src-templates-population-households-js" */),
  "component---src-templates-worker-affordability-js": () => import("./../../../src/templates/worker-affordability.js" /* webpackChunkName: "component---src-templates-worker-affordability-js" */)
}

